
label {
	font-size: 12px;
}
h2.xp {
	font-weight: bold;
	font-size: 28px;
}
.level {
	display: grid;
	grid-template-columns: 25px auto 25px;
	height: 25px;
	line-height: 25px;
	margin-bottom: 20px;

	.next {
		text-align: right;
	}
	.q-linear-progress {
		font-size: 15px !important;
		height: 25px;
	}
}
.btn.save {
	width: 49.5%;
}
